import React, {useEffect, useRef} from 'react';
import "../styles/About.css"

const ProfileSection = ({ profileSectionRef }) => {

    const article = useRef();

    useEffect(() => {
        
        const CONFIG = {
            proximity: 43,
            spread: 61,
            blur: 10,
            gap: 28,
            vertical: false,
            opacity: 0,
        };

        const isTouchEvent = (event) => {
          return event && (event.type.startsWith('touch') || event.touches);
        };

        const UPDATE = (event) => {
          
          if (isTouchEvent(event)) {
            return;
          }
            
            const ARTICLE_BOUNDS = article.current.getBoundingClientRect();
        
            if (
                event?.x > ARTICLE_BOUNDS.left - CONFIG.proximity &&
                event?.x < ARTICLE_BOUNDS.left + ARTICLE_BOUNDS.width + CONFIG.proximity &&
                event?.y > ARTICLE_BOUNDS.top - CONFIG.proximity &&
                event?.y < ARTICLE_BOUNDS.top + ARTICLE_BOUNDS.height + CONFIG.proximity
            ) {
              article.current.style.setProperty('--active', 1);
            } else {
              article.current.style.setProperty('--active', CONFIG.opacity);
            }
        
            const ARTICLE_CENTER = [
              ARTICLE_BOUNDS.left + ARTICLE_BOUNDS.width * 0.5,
              ARTICLE_BOUNDS.top + ARTICLE_BOUNDS.height * 0.5,
            ];
        
            let ANGLE = (Math.atan2(event?.y - ARTICLE_CENTER[1], event?.x - ARTICLE_CENTER[0]) * 180) / Math.PI;
            ANGLE = ANGLE < 0 ? ANGLE + 360 : ANGLE;
        
            article.current.style.setProperty('--start', ANGLE + 90);
            
        };

        const isTouchScreen = window.matchMedia('(hover: none) and (pointer: coarse)').matches;
        
        if (!isTouchScreen) {
          document.body.addEventListener('pointermove', UPDATE);
          UPDATE();
        }
        
        return () => {
            if (!isTouchScreen) {
              document.body.removeEventListener('pointermove', UPDATE);
            }
        };
    }, []); 

  return (
    <section ref={profileSectionRef} id="about" className="section">
      <div className="container">
        <article ref={article}>
          <div className="glows"></div>
          <div id="contentAboutMe">
            <div className="column" id="leftColumn">
              <div id="leftTopRow">
                <div className="profileImageContainer">
                  <img src="/MyPhoto.png" alt="Tu imagen" className="profileImage" />
                </div>
              </div>
              <div id="leftBottomRow">
              <h2>Contact Information</h2>
              <div className="contact-details">
                <p>Julio Montesinos Alvarado</p>
                <p>Currently based in Alicante, open to opportunities across Europe</p>
                <p><span className="flag">🇪🇸</span> +34 633 689 976</p>
                <p><span className="flag">🇧🇪</span> +32 475 291 724</p>
                <p>juliomontesinosalvarado@gmail.com</p>
              </div>
              <a href="/CV_Julio_Montesinos.pdf" download="CV_Julio_Montesinos.pdf">
                <button className="download-button">Download Resume</button>
              </a>
            </div>

            </div>
            <div className="column" id="rightColumn">
              <h1>About me</h1>
              <p>Hi, I’m Julio, a web developer with a strong background in building functional and user-friendly applications. I specialize in HTML, CSS, JavaScript, TypeScript, React, Node.js, and MongoDB, using these tools to create efficient and scalable web solutions.</p>
              <p>My expertise spans HTML, CSS, JavaScript, React, Node.js, and C++, which I have used to build dynamic and functional software solutions. During my time at the Department of Statistics, Mathematics, and Computer Science at UMH, I developed a platform for managing programming courses, integrating client-side execution of machine learning algorithms and implementing security measures like user authentication and authorization.</p>
              <p>One of my key achievements is ClockIn Smart, a time-tracking platform designed for casual workers. I developed this project using React and JavaScript, focusing on improving user experience and data management. I have also worked as a freelance developer, delivering custom web solutions, including a medical web platform that strengthened my ability to adapt to clients' needs.</p>
              <p>I am an ambitious person, always eager to grow both professionally and personally. I enjoy working on personal projects for fun and to sharpen my technical skills, which keeps me motivated and curious about new technologies. I’m constantly learning and pushing myself to improve, whether it’s through exploring new frameworks, solving challenging problems, or collaborating with others to create impactful solutions.</p>
              <p>My experience in Australia allowed me to improve my English communication skills and work effectively in diverse, collaborative environments. I’m a proactive learner and problem-solver, passionate about creating impactful web applications. Whether it’s frontend or full-stack development, I’m excited to contribute my skills to your team. 🚀</p>
          </div>
          </div>
        </article>
      </div>
    </section>

  );
}

export default ProfileSection;