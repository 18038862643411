import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <section id="footer">
      <div className="footer-content">
        <div className="social-links">
          <a href="https://github.com/JulioMontesinos" target="_blank" rel="noopener noreferrer">
            <img src="github_logo.png" alt="GitHub" />
          </a>
          <a href="https://www.linkedin.com/in/julio-montesinos/" target="_blank" rel="noopener noreferrer">
            <img src="/linkedin_logo.png" alt="LinkedIn" />
          </a>
        </div>
        <p>© 2025 <span className="my-name-footer">Julio Montesinos | </span> <a href="https://vercel.com/legal/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a></p>
      </div>
    </section>
  );
}

export default Footer;