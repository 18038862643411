import React, { useEffect, useRef, useState } from 'react';
import '../styles/CareerPath.css';

const Timeline = ({ careerPathSectionRef, homeRef, profileSectionRef, projectsSectionRef }) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [activeElements, setActiveElements] = useState([]);
  const [relativeScrollPos, setRelativeScrollPos] = useState(0);

  const agTimelineRef = useRef(null);
  const agTimelineLineRef = useRef(null);
  const agTimelineLineProgressRef = useRef(null);
  const agTimelineItemRefs = useRef([null]); //Array of balls of years
  const agTimelinePointRefs = useRef([null]);
  const thirdSectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const homeSectionSize = homeRef.current.getBoundingClientRect();
    const profileSectionSize = profileSectionRef.current.getBoundingClientRect();
    const projectsSectionSize = projectsSectionRef.current.getBoundingClientRect();
    const homeSectionHeight = homeSectionSize.height;
    const profileSectionHeight = profileSectionSize.height;
    const projectsSectionHeight = projectsSectionSize.height;

    const totalHeightOfFirstTwoSections = homeSectionHeight + profileSectionHeight + projectsSectionHeight;

    setRelativeScrollPos(Math.max(0, scrollPos + 100 - totalHeightOfFirstTwoSections));

    const agTimelineLineProgress = agTimelineLineProgressRef.current;
    agTimelineLineProgress.style.height = relativeScrollPos + 30 + "px";
  }, [homeRef, profileSectionRef, careerPathSectionRef, scrollPos]);

  useEffect(() => {

    /** The operation is such that when the top part of the ball reaches the top of the window,

    it means it will be 0, so I added 300 so that it takes the value 0 almost in the middle of the screen
    and not at the top of the window. So if the top part of any year is equal to 0 (lengthYear),
    it means it wouldn't be visible or very far away, so it will be deactivated, but if it is greater than or equal to 0,
    it means it is in the most visible part of the window, so we will show it. */

    // agTimelineLineRef is the length of the line. I have to set it to end at the last ball.

    if (agTimelineItemRefs.current.length > 0) { //If Years exists

      for(let i = 0; i < agTimelineItemRefs.current.length; i++){
        
        const lengthYear = agTimelinePointRefs.current[i].getBoundingClientRect().top - 300;

        if(lengthYear <= 0){

              setActiveElements((prevActiveElements) => {
                if (!prevActiveElements.includes(i)) {
                  return [...prevActiveElements, i];
                }
                return prevActiveElements;
              });

          } else{ //If it is greater than the position of the element, it is removed.
            setActiveElements((prevActiveElements) => prevActiveElements.filter((el) => el !== i));
          } 
      }
      
    }
  }, [agTimelineItemRefs, agTimelinePointRefs, scrollPos]);

  return (
    <div ref={thirdSectionRef} id="thirdSection">
      <div className="timelineBlock">
        <div className="jsTimeline timeline" ref={agTimelineRef}>
          <div className="jsTimeline_line timeline_line" ref={agTimelineLineRef}>
            <div className="jsTimeline_line-progress timeline_line_progress" ref={agTimelineLineProgressRef}></div>
          </div>
          {/* <!--INFO LEFT--> */}
          <div ref={ref => agTimelineItemRefs.current[0] = ref} className={`jsTimelineExperience timelineItem ${activeElements.includes(0) ? 'js-ag-active' : ''}`}>
            <div className="timelineItemContainer">
            <div className="jsTimelineYear_circle timelineYear_circle" ref={el => (agTimelinePointRefs.current[0] = el)}>
                <div className="timelineYear">2024</div>
              </div>
            </div>

            <div className="experience">
              <div className="experienceContent" >
                <div className="experienceTitle">Full Stack Software Developer (Freelance)</div>
                <div className="experienceLocationTime"> 📍Melbourne, VIC, Australia. Jul 23 - Aug 24.</div>
                <div className="experienceDescription">
                  <p>Developed robust full-stack applications and web solutions, providing optimized performance and tailored functionalities to meet specific client needs. Key projects included a medical web solution and the creation of ClockIn Smart, a time-tracking tool designed to enhance efficiency for casual workers.</p>
                  <ul>
                  <li>Designed and implemented responsive, accessible user interfaces using HTML, CSS, and JavaScript.</li>
                  <li>Utilized React, Bootstrap, and Chart.js for front-end development, while managing back-end with Express.js, MongoDB, and Redis.</li>
                  <li>Implemented authentication and authorization, API integration, and email notifications using SendGrid to ensure secure and seamless user interactions.</li>
                  <li>Collaborated closely with clients to deliver customized solutions, aligning technical features with business requirements.</li>
                  </ul>
                </div>
              </div>
              <div className="timelineArrow"></div>
            </div>

          </div>
          {/*----------------------------------------------------------------------------------------------------------*/}
          {/* <!--INFO RIGHT--> */}
          <div ref={ref => agTimelineItemRefs.current[1] = ref} className={`jsTimelineExperience timelineItem ${activeElements.includes(1) ? 'js-ag-active' : ''}`}>
            <div className="timelineItemContainer">
            <div className="jsTimelineYear_circle timelineYear_circle" ref={el => (agTimelinePointRefs.current[1] = el)}>
                <div className="timelineYear">2023</div>
              </div>
            </div>
            <div className="experience">
              <div className="experienceContent" >
                <div className="experienceTitle">English student, Navitas</div>
                <div className="experienceLocationTime"> 📍Perth, WA, Australia. Feb 23 - Sep 23.</div>
                <div className="experienceDescription">
                  <p>During my English language studies, I developed a solid foundation in English within a multicultural setting. Traveling to Australia was a strategic choice to enhance my language skills, build resilience, and adapt to new cultural challenges. Alongside my studies, I worked on personal software projects to further my technical skills.</p>
                  <ul>
                    <li>Achieved fluency in English, enabling effective communication in both informal and professional contexts.</li>
                    <li>Enhanced confidence in problem-solving and adaptability in unfamiliar situations.</li>
                    <li>Strengthened networking and interpersonal skills in a multicultural environment.</li>
                  </ul>
                </div>
              </div>
              <div className="timelineArrow"></div>
            </div>
          </div>
          {/*----------------------------------------------------------------------------------------------------------*/}
			{/* <!--INFO LEFT--> MIDDLE*/}
      <div ref={ref => agTimelineItemRefs.current[2] = ref} className={`jsTimelineExperience timelineItem ${activeElements.includes(2) ? 'js-ag-active' : ''}`}>
				<div className="timelineItemContainer">
				  <div className="jsTimelineYear_circle timelineYear_circle"  ref={el => (agTimelinePointRefs.current[2] = el)}>
				  </div>
				</div>
				<div className="experience">
					<div className="experienceContent">
						<div className="experienceTitle">Software developer, Inescop</div>
						<div className="experienceLocationTime"> 📍Elda, Spain. Jun 22 - Jan 23.</div>
						<div className="experienceDescription">
							<p>As a Software Developer at Inescop, I focused on advancing 3D geometry editing functionalities in C++ to support complex modeling needs. I collaborated closely with a multidisciplinary team to optimize file compatibility, implement algorithms, and meet the technical requirements for integrating polygonal shoe geometries into various metaverse platforms.</p>
							<ul>
								<li>Enhanced 3D polygonal geometry editing features, supporting advanced modeling capabilities in C++.</li>
								<li>Improved file compatibility through the development of efficient algorithms to ensure seamless integration of diverse file types.</li>
								<li>Collaborated on requirements gathering and integration of polygonal shoe geometries into multiple metaverse environments.</li>
							</ul>
						</div>
					</div>
				  <div className="timelineArrow"></div>
				</div>
			  </div>  
			  {/* <!----------------------------------------------------------------------------------------------------------> */}
       {/*  <!--INFO LEFT--> */}
       <div ref={ref => agTimelineItemRefs.current[3] = ref} className={`jsTimelineExperience timelineItem ${activeElements.includes(3) ? 'js-ag-active' : ''}`}>
				<div className="timelineItemContainer">
        <div className="jsTimelineYear_circle timelineYear_circle"  ref={el => (agTimelinePointRefs.current[3] = el)}>
					<div className="timelineYear">2022</div>
				  </div>
				</div>
				<div className="experience">
					<div className="experienceContent">
						<div className="experienceTitle">Bachelor's Degree in Computer Engineering in Information Technologies, Miguel Hernandez University</div>
						<div className="experienceLocationTime"> 📍Elche, Spain. Sep 17 - Jun 22.</div>
						<div className="experienceDescription">
							<p>During my studies, I developed strong skills in web development using HTML, CSS, JavaScript, PHP, SQL, and AJAX, following the model-view-controller (MVC) design pattern. I quickly adapted to various technologies, creating efficient algorithms in C++ and C, and implementing parallel programming with MPI and OpenMP. Additionally, I built Java applications and mobile apps with Android Studio, leveraging frameworks to enhance functionality.</p>
							<ul>
								<li>Awarded Honors in Mathematical Analysis for outstanding academic performance.</li>
								<li>Achieved a perfect score of 10/10 for my Final Degree Project, showcasing advanced problem-solving and technical skills.</li>
							</ul>
						</div>
					</div>
					<div className="timelineArrow"></div>
				</div>
			  </div>
			  {/* <!----------------------------------------------------------------------------------------------------------> */}  
        </div>
        {/* /FUERA DEL TIMELINE/ */}
        {/* <!--INFO RIGHT--> */}
			  <div ref={ref => agTimelineItemRefs.current[4] = ref} className={`jsTimelineExperience timelineItem ${activeElements.includes(4) ? 'js-ag-active' : ''}`}>
				<div className="timelineItemContainer">
        <div className="jsTimelineYear_circle timelineYear_circle"  ref={el => (agTimelinePointRefs.current[4] = el)}>
					<div className="timelineYear">2021</div>
				  </div>
				</div>
				<div className="experience">
					<div className="experienceContent">
						<div className="experienceTitle">Software Developer (Intern), Miguel Hernandez University</div>
						<div className="experienceLocationTime"> 📍Remote, Spain. Jan 21 - Sep 21.</div>
						<div className="experienceDescription">
							<p>Contributed to the development of a web application for managing programming courses, focusing on the integration of advanced features in both the frontend and backend. Additionally, worked on the design and documentation of data science models.</p>
							<ul>
								<li>Developed a web application for managing programming courses, integrating a frontend code editor for client-side machine learning (ML) algorithm execution.</li>
								<li>Designed and implemented backend functionalities, including database management, security protocols, and user role permissions.</li>
								<li>Conducted testing and documentation of data science algorithms using Kaggle and Google Colab, focusing on supervised and unsupervised learning models.</li>
							</ul>
						</div>
					</div>
				  <div className="timelineArrow"></div>
				</div>
			  </div> 
      </div>
    </div>
  );
};

export default Timeline;

